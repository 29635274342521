import React from 'react';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import UserForm from '../../components/user-form';

const AddUserPage = ({path}) => {
  const auth = useAuth();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Add user" />
      { auth && (
        <UserForm action="add" />
      )}
    </Layout>
  )
}

export default AddUserPage;
