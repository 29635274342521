import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TransitionAlert from './transition-alert';
import { EP, callApi } from '../api';
import useFormInput from './use-form-input';
import { USER_ROLES } from '../constants';
import useFetchStores from './use-fetch-stores';
import Loading from './loading';
import { toUsername } from '../utils';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
}));

const getStoreValue = (stores, position) => {
  try {
    return JSON.parse(stores)[position];
  } catch (e) {
    return '';
  }
}

function UserForm({
  action,
  user,
}) {
  const { t } = useTranslation('userForm');
  const classes = useStyles();
  const { stores, isLoading: isLoadingStores, isError: isErrorStores } = useFetchStores();
  const username = useFormInput(user ? user.username : '', toUsername);
  const email = useFormInput(user ? user.email : '');
  const givenName = useFormInput(user ? user.givenName : '');
  const name = useFormInput(user ? user.name : '');
  const role = useFormInput(user ? user.role : '');
  const store0 = useFormInput(user ? getStoreValue(user.stores, 0) : '');
  const store1 = useFormInput(user ? getStoreValue(user.stores, 1) : '');
  const store2 = useFormInput(user ? getStoreValue(user.stores, 2) : '');
  const storesAux = useFormInput(user && user.stores !== 'ALL' ? 'select' : 'all');
  const initialFormState = { isSubmitting: false, isSuccess: false, error: null }
  const [form, setForm] = useState(initialFormState); 
  const isInvalidForm = (
    !username.isValid 
    || !email.isValid 
    || !givenName.isValid 
    || !name.isValid 
    || !role.isValid 
    || (storesAux.value !== 'all' && !store0.isValid)
  ); 
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setForm({ ...initialFormState, isSubmitting: true });
    const storesValue = storesAux.value === 'all'
      ? 'ALL'
      : JSON.stringify([store0.value, store1.value, store2.value].filter(i => i));
    const params = Object.assign(
      {
        body: Object.assign(
          {
            email: email.value,
            givenName: givenName.value,
            name: name.value,
            role: role.value,
            stores: storesValue,
          },
          action === 'add' ? { username: username.value } : {}
        )
      }, 
      action === 'edit' ? { username: user.username } : {}
    );
    try {
      const endpoint = action === 'add' ? EP.users.post : EP.users.patch;
      await callApi(endpoint, params);
      setForm({ ...initialFormState, isSuccess: true});
    } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message)
        || t('errorSaving'); 
      setForm({ ...initialFormState, error: message });
    }
  }
  const isAddSuccess = form.isSuccess && action === 'add';
  if (isLoadingStores) return <Loading />;
  if (isErrorStores) return (
    <TransitionAlert className={classes.alert} severity="error" isOpen>{t('errorFetchingStores')}</TransitionAlert>
  );
  return (
    <Container component="main" maxWidth="xs">
      { isAddSuccess && (
        <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('messageSuccess')}</TransitionAlert>
      )}
      { form.isSuccess && (
        <Button 
          className={classes.buttons}
          component={Link}
          to="/users" 
          fullWidth  
          variant="contained"
          color="primary"
        >
            {t('seeAll')}
        </Button>
      )}
      { form.isSuccess && action ==='add' && (
        <Button 
          className={classes.buttons}
          fullWidth  
          variant="outlined"
          onClick={() => setForm(initialFormState)}
        >
            {t('addAnotherUser')}
        </Button>
      )}
      { !isAddSuccess && (
          <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            { action === 'add' ? t('titleAdd') : t('titleEdit') }
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              autoFocus
              readOnly={action === 'edit'}
              fullWidth
              id="username"
              label={t('labelUsername')}
              margin="normal"
              name="username"
              required
              type="text"
              variant="outlined"
              value={username.value}
              onChange={username.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="email"
              label={t('labelEmail')}
              margin="normal"
              name="email"
              required
              type="email"
              variant="outlined"
              value={email.value}
              onChange={email.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="givenName"
              label={t('labelFirstname')}
              margin="normal"
              name="givenName"
              required
              type="text"
              variant="outlined"
              value={givenName.value}
              onChange={givenName.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              fullWidth
              id="name"
              label={t('labelLastname')}
              margin="normal"
              name="name"
              required
              type="text"
              variant="outlined"
              value={name.value}
              onChange={name.onChange}
            />
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel id="store-label">{t('labelRole')}</InputLabel>
              <Select
                labelId="store-label"
                id="store"
                value={role.value}
                onChange={role.onChange}
                label={t('labelRole')}
              >
                <MenuItem value={USER_ROLES.TENANT_ADMIN}>{t('roleAdmin')}</MenuItem>
                <MenuItem value={USER_ROLES.TENANT_USER}>{t('roleEmployee')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{t('labelStores')}</FormLabel>
              <RadioGroup aria-label="stores" name="storesAux" value={storesAux.value} onChange={storesAux.onChange}>
                <FormControlLabel value="all" control={<Radio />} label={t('labelAllStores')} />
                <FormControlLabel value="select" control={<Radio />} label={t('labelChooseStores')} />
              </RadioGroup>
            </FormControl>  
            { storesAux.value === 'select' && (
              <React.Fragment>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel id="store0-label">{t('labelStore')} 1</InputLabel>
                  <Select
                    labelId="store0-label"
                    id="store0"
                    value={store0.value}
                    onChange={store0.onChange}
                    label={`${t('labelStore')} 1`}
                  >
                    { store0.value && <MenuItem>{t('labelNone')}</MenuItem> }
                    { stores.filter(s => (![store1.value, store2.value].includes(s.id))).map( s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) }
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel id="store1-label">{t('labelStore')} 2</InputLabel>
                  <Select
                    labelId="store1-label"
                    id="store1"
                    value={store1.value}
                    onChange={store1.onChange}
                    label={`${t('labelStore')} 2`}
                  >
                    { store1.value && <MenuItem>{t('labelNone')}</MenuItem> }
                    { stores.filter(s => (![store0.value, store2.value].includes(s.id))).map( s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) }
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel id="store2-label">{t('labelStore')} 3</InputLabel>
                  <Select
                    labelId="store2-label"
                    id="store2"
                    value={store2.value}
                    onChange={store2.onChange}
                    label={`${t('labelStore')} 3`}
                  >
                    { store2.value && <MenuItem>{t('labelNone')}</MenuItem> }
                    { stores.filter(s => (![store0.value, store1.value].includes(s.id))).map( s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) }
                  </Select>
                </FormControl>
              </React.Fragment>
            )}
            <div>
              <TransitionAlert className={classes.alert} severity="error" isOpen={form.error}>{form.error}</TransitionAlert>
              <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('messageSuccess')}</TransitionAlert>
            </div>
            <Grid container spacing={1} className={classes.buttons}>
              <Grid item xs={6}>
                <Button 
                  component={Link} 
                  type="button"
                  fullWidth
                  variant="outlined"
                  to="/users"  
                >
                    {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={form.isSubmitting || isInvalidForm}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleOnSubmit}
                >
                  { form.isSubmitting ? t('saving') : t('save') }
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Container>
  );
}

export default UserForm;
